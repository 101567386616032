<template>
    <div class="product_home">
        <div class="left">
            <van-sidebar v-model="active" @change="handleCategoryClick">
                <van-sidebar-item :title="item.title" v-for="(item,index) in categoryData" :key="index" />
            </van-sidebar>
        </div>
        <div class="right product">
            <div class="product_box" v-if="productData.length">
                <van-card :price="`${(pitem.money / 100).toFixed(2)}`" :desc="pitem.intro"
                    :title="pitem.title" :thumb="pitem.cover" :thumb-link="`${'/product_detail?productId=' + pitem.id}`"
                    v-for="(pitem, pindex) in productData" :key="pindex">
                    <template #footer>
                        <div class="product_buy" @click="toOrderBuy(pitem.id)">
                            <van-icon name="cart-circle" size="32" />
                        </div>
                    </template>
                </van-card>
            </div>
            <div class="product_box" v-if="!productData.length">
                <van-empty description="暂无产品" />
            </div>
        </div>
    </div>
    <global-menu></global-menu>
</template>
<script>
import { ref,onBeforeMount } from 'vue';
import request from '../../utils/request';
import GlobalMenu from "../../components/GlobalMenu.vue";
import { useRoute,useRouter } from "vue-router";
export default {
    components: {
        GlobalMenu,
    },
    setup() {
        const route = useRoute();
        const active = ref(0);
        const categoryData = ref([]);
        const productData = ref([]);
        const productListData = ref([]);
        const loadProductData = () => {
            request({
                url: '/app/product',
                method: 'GET'
            }).then((res) => {
                if (res && res.items) {
                    var categoryTemp = [];
                    var productTemp = [];
                    res.items.forEach(element => {
                        categoryTemp.push({
                            'id': element.category_id,
                            'title': element.title
                        })
                        productTemp.push(element.list)
                    });
                    categoryData.value = categoryTemp
                    productListData.value = productTemp
                    productData.value = productTemp[active.value]
                }
            });
        };
        const handleCategoryClick = (index) => {
            console.log(index)
            var productTemp = productListData.value
            productData.value = productTemp[index]
            active.value = index
        };
        onBeforeMount(() => {
            if(route.query.categoryid){
                active.value = route.query.categoryid
            }
            loadProductData()
        });
        const router = useRouter();
        const toOrderBuy = (id) => {
            router.push({name:"Cart",query:{productid:id}})
        };
        return {
            active,
            categoryData,
            productData,
            handleCategoryClick,
            toOrderBuy,
        }
    },
}
</script>
<style>
.product_home{overflow: hidden;display: flex;}
.product_home .left{}
.product_home .right{flex: 1;}
</style>